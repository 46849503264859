import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const SayHello = props => (
  <Layout>
    {/* <Helmet>
      <title>Generic - Forty by HTML5 UP</title>
      <meta name="description" content="Generic Page" />
    </Helmet> */}
    <Helmet
      title="Say Hello | Portfolio Sagar Satam"
      meta={[
        { name: 'Design', content: 'Portfolio' },
        { name: 'Design', content: 'product design, designer' },
      ]}
    />

    <div id="main" className="alt">
      <section id="title-section" className="title-block inner">
        <div className="hp-featured">
          <h1 className="hp-featured__title">
            say hello :)
          </h1>
        
        <div className="hp-featured__content">
        {/* <div className="hp-featured__hr-line" /> */}
          <h5 className="hp-featured__text">
          If you would like to know more about a project 
          or want to have a chat about a topic or are 
          interested to collaborate in some way, then 
          just drop me a line and lets make amazing 
          things happen!
          </h5>
        </div>
        </div>
      </section>



      {/* the css below is defined in the work.scss page */}
      <div className="project-wrapper">
        <div className="project-content">
        <section className="process-section">
        
      {/* the css below is defined in the work.scss page */}
        <p className="full-textlarge-center">
        sagar.satam25@gmail.com <br />
        {/* +61 422 690 093 <br /> */}
        +65 8907 5581 <br />
        Singapore
        </p>
        <br />
        <br />
        <br />
        <br />
        <div className="process">           
            <div className="process-left">
                  <h4>
                  Follow me on
                  </h4>
            </div>
            <div className="process-right">

              
            <a href={"https://medium.com/@sagarsatam"} target="_blank" rel="noopener noreferrer">
              <div className="topline">
              <p>Medium</p>
              </div>
            </a>

            <a href={"https://www.linkedin.com/in/satamsagar/"} target="_blank" rel="noopener noreferrer">  
              <div className="topline">
              <p>LinkedIn</p>
              </div>
            </a> 

            {/* <a href={"https://dribbble.com/sagarsatam"} target="_blank" rel="noopener noreferrer"> 
              <div className="topline">
              <p>Dribble</p>
              </div>
            </a>   */}

            <a href={"https://www.instagram.com/sagar.satam/"} target="_blank" rel="noopener noreferrer">
              <div className="topline">
              <p>Instagram</p>
              </div>
            </a>  

          </div> 
        </div>

      

        </section>
        </div>
      </div>
          





      {/* <section id="sayhello" className="sayhello inner">
        <div className="col1">
          <div className="say-hello-content">
            <div className="hp-featured__hr-line" />
          </div>
          <div className="description">
            If you would like to know more about a project or want to have a
            chat about a topic or are interested to collaborate in some way,
            then just drop me a line and lets make amazing things happen!
          </div> */}


          {/* already commented out before (lines below this) */}
          {/* <div className="form">
            <div className="name">
              <input type="text" />
              <label for="" className="name">
                Name
              </label>
            </div>
            <div className="email">
              <input type="email" />
              <label for="" className="email">
                Email
              </label>
            </div>
            <div className="message">
              <label for="" className="message">
                Message
              </label>
              <textarea rows="4" cols="50">
                Write your message here.
              </textarea>
            </div>
            <div className="submit">
              <button type="submit">LET'S TALK</button>
            </div>
          </div> */}
          {/* already commented out before (lines above this) */}

          
          {/* <div className="text">Or you can connect with me on:</div>
          <div className="social">
            <ul className="icons">
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://twitter.com/sagarsatam13"
                  className="icon alt fa-twitter"
                >
                  <span className="label">Twitter</span>
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/sagar.satam/"
                  className="icon alt fa-instagram"
                >
                  <span className="label">Instagram</span>
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://in.pinterest.com/sagarsatam/"
                  className="icon alt fa-pinterest"
                >
                  <span className="label">Pinterest</span>
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/in/satamsagar/"
                  className="icon alt fa-linkedin"
                >
                  <span className="label">LinkedIn</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col2">
          <div className="contact-info">
            <div className="emailid">sagar.satam25@gmail.com</div>
            <div className="phone">+39 329 857 3123</div>
            <div className="location">Milan, Italy</div>
          </div>
        </div>
      </section> */}



      <section id="footertopcta" className="inner">
        <p className="text arrow-front">
        <a href="/work">till i get back to you,
          check out all my work</a>
        </p>
      </section>
    </div>
  </Layout>
)

export default SayHello
